import { render, staticRenderFns } from "./headTip.vue?vue&type=template&id=5b5b36f0&scoped=true"
import script from "./headTip.vue?vue&type=script&lang=js"
export * from "./headTip.vue?vue&type=script&lang=js"
import style0 from "./headTip.vue?vue&type=style&index=0&id=5b5b36f0&prod&lang=css"
import style1 from "./headTip.vue?vue&type=style&index=1&id=5b5b36f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5b36f0",
  null
  
)

export default component.exports