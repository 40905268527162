<template>
  <div>
    <div v-if="isMobile===false" class="PC">
      <el-dialog
          :before-close="handleClose"
          :visible.sync="dialogVisible"
          style=" overflow-y: auto"
          width="85vw">
        <div>
          <div class="title1" ><span style="color:#be0000">Product Evaluation </span><span>Feedback</span></div>
          <div class="title2">
            <span style="text-align: left;margin-bottom: 2vh">Dear Valued Customer：
</span><br>
            Thank you for choosing Anton Oilfield Services Group’s products! To assist us in continuously improving our product quality and service, please take a moment to evaluate your experience with our products. Your valuable feedback, opinions, or main_contents are crucial for our ongoing development. We greatly appreciate your time and continued support!
          </div>
          <div style="margin-top:15px;background-color: #d0dced">

            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="30%">
              <el-form-item style="margin-bottom: 50px" label="Product Name" prop="project_name">
                <el-input v-model="ruleForm.project_name" placeholder="please enter your Product Name"></el-input>
              </el-form-item>
              <el-form-item style="background-color: #e8edf5" label="Region of use" prop="job_location">
                <el-input v-model="ruleForm.job_location" placeholder="please enter your region of use"></el-input>
              </el-form-item>
              <div class="starClas">
                <div class="starClasleft">
                  <div style="width: 40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">number</div>
                  <div style="width: 60%">Evaluation content</div>
                </div>
                <div class="starClasright">Evaluation</div>
              </div>
              <div style="display: flex">
                <div style="width: 65%">
                  <div class="starClas" style="background-color: #e8edf5">
                    <div class="starClasleft" style="width: 46%">
                      <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">1</div>
                      <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Product Quality</div>
                    </div>
                    <div class="starClasright" style="width: 54%">
                      <div class="evalStar">
                        <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradeproduct_service_quality(index)">
                          <img v-if="ruleForm.product_service_quality>=index" class="starImg" src="@/assets/star3.png" >
                          <img v-else  class="starImg" src="@/assets/star1.png"  >
                        </div >
                      </div >
                    </div>
                  </div>
                  <div class="starClas" >
                    <div class="starClasleft" style="width: 46%">
                      <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">2</div>

                      <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Product Performance</div>
                    </div>
                    <div class="starClasright" style="width: 54%"> <div class="evalStar">
                      <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradeproduct_performance_hse(index)">
                        <img v-if="ruleForm.product_performance_hse>=index" class="starImg" src="@/assets/star3.png" >
                        <img v-else  class="starImg" src="@/assets/star1.png"  >
                      </div >
                    </div ></div>
                  </div>
                  <div class="starClas" style="background-color: #e8edf5">
                    <div class="starClasleft" style="width: 46%">
                      <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">3</div>

                      <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Timely Delivery</div>
                    </div>
                    <div class="starClasright" style="width: 54%"> <div class="evalStar">
                      <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradedelivery_timeliness(index)">
                        <img v-if=" ruleForm.delivery_timeliness>=index" class="starImg" src="@/assets/star3.png" >
                        <img v-else  class="starImg" src="@/assets/star1.png"  >
                      </div >
                    </div ></div>
                  </div>
                  <div class="starClas" >
                    <div class="starClasleft" style="width: 46%">
                      <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">4</div>

                      <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Product Cost-effectiveness </div>
                    </div>
                    <div class="starClasright" style="width: 54%"> <div class="evalStar">
                      <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradeproduct_cost_performance(index)">
                        <img v-if="ruleForm.product_cost_performance>=index" class="starImg" src="@/assets/star3.png" >
                        <img v-else  class="starImg" src="@/assets/star1.png"  >
                      </div >
                    </div ></div>
                  </div>
                  <div class="starClas"  style="background-color: #e8edf5">
                    <div class="starClasleft" style="width: 46%">
                      <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">5</div>

                      <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Service Personnel’s performance</div>
                    </div>
                    <div class="starClasright" style="width: 54%;height: 100%"> <div class="evalStar">
                      <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradestaff_performance(index)">
                        <img v-if="ruleForm.staff_performance>=index" class="starImg" src="@/assets/star3.png" >
                        <img v-else  class="starImg" src="@/assets/star1.png"  >
                      </div >
                    </div ></div>
                  </div>
                </div>

                <div style="border-top:2px solid white;background-color: #e8edf5;line-height:25px;width: 35%;padding:0 35px;display: flex;justify-content: center;align-items: center;word-break:normal">
                  Note: Each evaluation has 5 stars, 5 stars being very good, 4 stars being good, 3 stars being fair, 2 stars being poor and 1 star being very poor.
                </div>
              </div>

              <el-form-item  label="Additional Comments or main_contents" prop="main_content">

                <el-input v-model="ruleForm.main_content" placeholder="Note: Please feel free to share any additional thoughts or recommendations about issues or areas for improvement encountered during the project."></el-input>

              </el-form-item>
              <el-form-item  style="background-color: #e8edf5"  label="Company Name" prop="work_unit">
                <el-input v-model="ruleForm.work_unit" placeholder="please enter your company name"></el-input>
              </el-form-item>
              <el-form-item label="Your Name" prop="username">
                <el-input v-model="ruleForm.username" placeholder="please enter your your name"></el-input>
              </el-form-item>
              <el-form-item label="Your Position" prop="job">
                <el-input v-model="ruleForm.job" placeholder="please enter your your position"></el-input>
              </el-form-item>
              <el-form-item style="background-color: #e8edf5" label="Contact Details（Phone/Euser_contact）" prop="user_contact">
                <el-input v-model="ruleForm.user_contact" placeholder="Please be assured that your contact information will be kept confidential and used solely for internal reference"></el-input>
              </el-form-item>
              <el-form-item  style="background-color: #e8edf5"  label="Attachments" prop="attachment">
                <el-upload
                    ref="field102"
                    :file-list="fileList"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :before-upload="fileUpload"
                    class="inputStyle"
                    :show-file-list="true"
                    action="/api/file/upload"
                    multiple>
                  <div style="display: flex;margin-left: 15px">
                    <div><i class="el-icon-upload"></i></div>
                    <div class="el-upload__text" style="margin-left: 10px"><em>
                      （Please upload, if applicable)</em></div>
                  </div>
                   </el-upload>
              </el-form-item>
              <el-form-item style="text-align: center">

              </el-form-item>
            </el-form>
          </div>
          <div style="text-align: center" class="submitStyle" @click="submitForm(ruleForm)">Submit
          </div>
        </div>
      </el-dialog>
    </div>
    <div v-if="isMobile===true" class="phone" style="font-size: 14px">
      <div class="topback">
        <headtip @showDialog="showPartner"></headtip>
      </div>
      <div class="title1" ><span style="color:#be0000">Product Evaluation </span><span>Feedback</span></div>
      <div class="title2">
            <span style="text-align: left;margin-bottom: 2vh">Dear Valued Customer：
</span><br>
        Thank you for choosing Anton Oilfield Services Group’s products! To assist us in continuously improving our product quality and service, please take a moment to evaluate your experience with our products. Your valuable feedback, opinions, or main_contents are crucial for our ongoing development. We greatly appreciate your time and continued support!
      </div>
      <div style="margin-top:15px;background-color: #d0dced">

        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="30%">
          <el-form-item style="margin-bottom: 50px" label="Product Name" prop="project_name">
            <el-input v-model="ruleForm.project_name" placeholder="please enter your Product Name"></el-input>
          </el-form-item>
          <el-form-item style="background-color: #e8edf5" label="Region of use" prop="job_location">
            <el-input v-model="ruleForm.job_location" placeholder="please enter your region of use"></el-input>
          </el-form-item>
          <div class="starClas">
            <div class="starClasleft">
<!--/*              <div style="width: 40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">number</div>-->
              <div style="width: 60%">Evaluation content</div>
            </div>
            <div class="starClasright">Evaluation</div>
          </div>
          <div style="display: flex">
            <div style="width: 65%">
              <div class="starClas" style="background-color: #e8edf5">
                <div class="starClasleft" style="width: 46%">
<!--                  <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">1</div>-->
                  <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Product Quality</div>
                </div>
                <div class="starClasright" style="width: 54%">
                  <div class="evalStar">
                    <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradeproduct_service_quality(index)">
                      <img v-if="ruleForm.product_service_quality>=index" class="starImg" src="@/assets/star3.png" >
                      <img v-else  class="starImg" src="@/assets/star1.png"  >
                    </div >
                  </div >
                </div>
              </div>
              <div class="starClas" >
                <div class="starClasleft" style="width: 46%">
<!--                  <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">2</div>-->

                  <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Product Performance</div>
                </div>
                <div class="starClasright" style="width: 54%"> <div class="evalStar">
                  <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradeproduct_performance_hse(index)">
                    <img v-if="ruleForm.product_performance_hse>=index" class="starImg" src="@/assets/star3.png" >
                    <img v-else  class="starImg" src="@/assets/star1.png"  >
                  </div >
                </div ></div>
              </div>
              <div class="starClas" style="background-color: #e8edf5">
                <div class="starClasleft" style="width: 46%">
<!--                  <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">3</div>-->

                  <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Timely Delivery</div>
                </div>
                <div class="starClasright" style="width: 54%"> <div class="evalStar">
                  <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradedelivery_timeliness(index)">
                    <img v-if=" ruleForm.delivery_timeliness>=index" class="starImg" src="@/assets/star3.png" >
                    <img v-else  class="starImg" src="@/assets/star1.png"  >
                  </div >
                </div ></div>
              </div>
              <div class="starClas" >
                <div class="starClasleft" style="width: 46%">
<!--                  <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">4</div>-->

                  <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Product Cost-effectiveness </div>
                </div>
                <div class="starClasright" style="width: 54%"> <div class="evalStar">
                  <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradeproduct_cost_performance(index)">
                    <img v-if="ruleForm.product_cost_performance>=index" class="starImg" src="@/assets/star3.png" >
                    <img v-else  class="starImg" src="@/assets/star1.png"  >
                  </div >
                </div ></div>
              </div>
              <div class="starClas"  style="background-color: #e8edf5">
                <div class="starClasleft" style="width: 46%">
<!--                  <div style="width:40%;border-right: 2px solid white;height: 100%;display: flex;align-items: center;justify-content: center">5</div>-->

                  <div style="width: 60%;display: flex;justify-content: center;align-items: center"><img style="height: 14px;width: fit-content"  src="@/assets/star.png"/>Service Personnel’s performance</div>
                </div>
                <div class="starClasright" style="width: 54%;height: 100%"> <div class="evalStar">
                  <div  class="starItem" v-for="index in 5" :key="index" @click="changeGradestaff_performance(index)">
                    <img v-if="ruleForm.staff_performance>=index" class="starImg" src="@/assets/star3.png" >
                    <img v-else  class="starImg" src="@/assets/star1.png"  >
                  </div >
                </div ></div>
              </div>
            </div>

            <div style="border-top:2px solid white;background-color: #e8edf5;line-height:25px;width: 35%;padding:0px;display: flex;justify-content: center;align-items: center;word-break:normal">
               5 stars : very good<br>4 stars : good<br>3 stars : fair<br> 2 stars : poor <br> 1 star : very poor.
            </div>
          </div>

          <el-form-item  label="main_contents" prop="main_content">

            <el-input v-model="ruleForm.main_content" placeholder="Note: Please feel free to share any additional thoughts or recommendations about issues or areas for improvement encountered during the project."></el-input>

          </el-form-item>
          <el-form-item  style="background-color: #e8edf5"  label="Company Name" prop="work_unit">
            <el-input v-model="ruleForm.work_unit" placeholder="please enter your company name"></el-input>
          </el-form-item>
          <el-form-item label="Your Name" prop="username">
            <el-input v-model="ruleForm.username" placeholder="please enter your your name"></el-input>
          </el-form-item>
          <el-form-item label="Your Position" prop="job">
            <el-input v-model="ruleForm.job" placeholder="please enter your your position"></el-input>
          </el-form-item>
          <el-form-item style="background-color: #e8edf5" label="Contact Details" prop="user_contact">
            <el-input v-model="ruleForm.user_contact" placeholder="Please be assured that your contact information will be kept confidential and used solely for internal reference"></el-input>
          </el-form-item>
          <el-form-item  style="background-color: #e8edf5"  label="Attachments" prop="attachment">
            <el-upload
                ref="field102"
                :file-list="fileList"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="fileUpload"
                class="inputStyle"
                :show-file-list="true"
                action="/api/file/upload"
                multiple>
              <div style="display: flex;margin-left: 15px">
                <div><i class="el-icon-upload"></i></div>
                <div class="el-upload__text" style="margin-left: 10px"><em>
                  （Please upload, if applicable)</em></div>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item style="text-align: center">

          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center" class="submitStyle" @click="submitForm(ruleForm)">Submit
      </div>

<bottomTip></bottomTip>

    </div>
    <successTip
        :dialogVisible="dialogconsult"
        @hideDialog="hide"
    ></successTip>

  </div>

</template>
<script>
import bottomTip from "@/views/bottomTip.vue";
import headtip from "@/views/headTip.vue";
import successTip from "@/components/successTip.vue";
export default {
  components: {successTip,headtip,bottomTip},
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    thisTitle: {
      type: String,
      // required: true
    }
  },
  data() {
    return {

      // localDialogVisible: this.dialogVisible,
      userId:localStorage.getItem("userId"),
      dialogconsult: false,
      complete:localStorage.getItem("infoComplete"),
      token: localStorage.getItem("access_token"),
      isMobile: false,
      languageSelect: '',
      title:'',
      grade:0,
      fileList: [],
      ruleForm: {
       feedback_title:'产品评价反馈',
        project_name: '',
        job_location: '',
        product_service_quality: 0,
        product_performance_hse: 0,
        delivery_timeliness: 0,
        product_cost_performance: 0,
        staff_performance: 0,
        main_content: "",
        work_unit:'',
        username: '',
        job:'',
        user_contact:'',
        attachment:'',
      },
      rules: {
        project_name: [
          {required: true, message: 'please enter your product name', trigger: 'blur'},
        ],
        job_location: [
          {required: true, message: 'please enter your region of use', trigger: 'blur'}
        ],
        product_service_quality:[
          {required: true, message: 'Please click comment', trigger: 'blur'}
        ],
        product_performance_hse:[
          {required: true, message: 'Please click comment', trigger: 'blur'}
        ],
        delivery_timeliness: [
          {required: true, message: 'Please click comment', trigger: 'blur'},
        ],
        product_cost_performance: [
          {required: true, message: 'Please click comment', trigger: 'blur'}
        ],
        staff_performance:[
          {required: true, message: 'Please click comment', trigger: 'blur'}
        ],
        main_content:[
            {required: true, message: 'please enter your suggestions', trigger: 'blur'}
            ],
        work_unit:[
          {required: true, message: 'please enter your company name', trigger: 'blur'}
        ]

      }
    }
  },
  mounted() {

    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  computed(){

  },
  created() {
    console.log(typeof (this.thisTitle))
    if(this.thisTitle===undefined||this.thisTitle===""){
      this.thisTitle="安东地质工程云平台"
    }
    console.log(this.title)
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }

  },
  methods: {
    changeGradeproduct_service_quality(num){
      let that = this
      that.$data.ruleForm.product_service_quality=num
    },
    changeGradeproduct_performance_hse(num){
      let that = this
      that.$data.ruleForm.product_performance_hse=num
    },
    changeGradedelivery_timeliness(num){
      let that = this
      that.$data.ruleForm.delivery_timeliness=num
    },
    changeGradeproduct_cost_performance(num){
      let that = this
      that.$data.ruleForm.product_cost_performance=num
    },
    changeGradestaff_performance(num){
      let that = this
      that.ruleForm.staff_performance=num
      that.$set(that.ruleForm.staff_performance,num)
      console.log(that.ruleForm.staff_performance)
    },
    hideDialogin() {
      // this.dialogVisibleLogin = false;
      // location.reload()
      this.$forceUpdate()
    },
    hide() {
      this.dialogconsult = false;
      // location.reload()
      this.$forceUpdate()
    },
    hideComplete(){
      // location.reload()
      // this.$forceUpdate()
      // this.dialogComplete = false;
      // this.localDialogVisible=false
    },
    handleClose() {
      this.ruleForm.project_name=""
      this.ruleForm. job_location=""
      this.ruleForm. product_service_quality=""
      this.ruleForm. product_performance_hse=""
      this.ruleForm. delivery_timeliness=""
      this.ruleForm. product_cost_performance=""
      this.ruleForm. staff_performance=""
      this.ruleForm. main_content=""
      this.ruleForm. work_unit=""
      this.ruleForm. username=""
      this.ruleForm. job=""
      this.ruleForm. user_contact=""
      this.ruleForm. attachment=""
      this.$emit('hideDialog')
    },
    submitForm(formName) {
      formName.consultUrl = window.location.href
      console.log(formName)
      if (formName.project_name === "") {
        this.$message('please enter your Product Name');
      } else if (formName.job_location === "") {
        this.$message('please enter your Region of use');
      }
      else if (formName.product_service_quality === 0) {
        this.$message('Please click comment.');
      }
      else if (formName.product_performance_hse === 0) {
        this.$message('Please click comment.');
      }
      else if (formName.delivery_timeliness === 0) {
        this.$message('Please click comment.');
      }
      else if (formName.product_cost_performance === 0) {
        this.$message('Please click comment.');
      }
      else if (formName.main_content ==="" ) {
        this.$message('Please enter your suggestions');
      }
      else if (formName.staff_performance === 0 ){
        this.$message('Please click comment.');
      }

      else if (formName.work_unit === "") {
        this.$message('please enter your 单位名称');
      } else {
        this.$axios.post("/ajax/geologicalgpt/feedback", formName,{
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token
          },
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.dialogconsult = true
                this.handleClose()
                this.resetForm(formName)
              } else {
                this.$message('Failed to submit, please try again.');
              }

            }).catch(error => {
          console.log(error)
        })

      }

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert('submit!');
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    // 文件上传
    async fileUpload(file) {
      try {
        // 构建表单数据
        const formData = new FormData();
        formData.append('file', file);

        // 向后端发送文件到 /common/upload 接口
        const response = await this.$axios.post('/api/file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.uploadedFileValue = response.data;
        console.log(response.data); // 打印响应数据到控制台
        this.ruleForm.attachment=response.data.msg
        // 返回 true 允许上传，false 禁止上传
        return true;
      } catch (error) {
        console.error('Failed to upload.:', error);
        return false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("预览")
      console.log(file);
      // this.showModal = true;
      window.open(file.response.url)
      // this.largeSrc=file.response.url
    },

    beforeRemove(file, fileList) {
      console.log(fileList)
      return this.$confirm(`Definitive removal  ${ file.name }？`);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    "$store.state.userId"(newValue) {
      this.userId = newValue;
    },
    // dialogVisible() {
    //   this.localDialogVisible = false;
    //   this.dialogComplete = false;
    //
    //   // if (newVal&&!this.userId) {
    //   //   // 如果 dialogVisible 为 true 且 userId 不存在，设置 localDialogVisible 为 true
    //   //   this.dialogVisibleLogin=true
    //   //   this.localDialogVisible = false;
    //   // }
    //   // else if(newVal&&this.userId){
    //   //   if(this.complete==1){
    //   //     this.localDialogVisible = true;
    //   //   }
    //   //   else{
    //   //     this.dialogComplete = true;
    //   //     this.localDialogVisible = false;
    //   //   }
    //   // }
    //   // else{
    //   //   this.localDialogVisible = false;
    //   //   this.dialogComplete = false;
    //   // }
    // },
  },
};
</script>
<style lang="scss" scoped>
.PC{

  ::v-deep .el-upload-list__item:first-child{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
  }
  ::v-deep .el-upload-list__item{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
    border-radius: 0;
  }
  .title1 {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    margin-top: -35px;
  }

  .title2 {
    color: gray;
    text-align: left;
    width: 100%;
    font-size: 16px;
    margin-top: 15px;
    line-height: 25px;
  }
  .submitStyle{
    cursor: pointer;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content:  center;;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #cdd9ea
  }
  .submitStyle:hover{
    //font-weight: bold;
    background-color: #efdec3
  }
  .starClas{
    height: 35px;
    display: flex;
    justify-content:  flex-start;;
    align-items: center;
    font-size: 14px;
    border-top: 2px solid white;
    .starClasleft {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      font-size: 14px;
      height: 100%;
      border-right: 2px solid white;
    }
    .starClasright{
      width: 70%;
      height: 100%;
      border-right: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      .evalTitle{
        width: 100%;
        text-align: center;
        padding-top: 20px;
      }
      /* 评价星星 */
      .evalStar{
        width: 100%;
        display: flex;
        text-align: center;
        margin:0 auto;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        //padding-left: calc(50% - 100px);
      }
      .starImg{
        cursor: pointer;
        width: 20px;
        margin:0 5px;
        height: 20px;
      }

    }

  }
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
    border-top:2px solid white;
  }
  ::v-deep .el-form-item__error {
    left: 15px !important;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
  }


  ::v-deep {
    .el-dialog__body {
      padding: 30px 50px 30px 35px;
      //color: #606266;
      font-size: 14px;
      word-break: break-all;
    }

    .el-dialog__header {
      padding: 10px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }

    .el-input__inner {
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      border-radius: 5px !important;
      border: none !important;
      height: 30px;
      background-color: transparent;

    }

    .el-textarea {
      line-height: 35px;
      border-radius: 5px !important;
      border: 1px solid #9b9a9a !important;
      position: relative;
      font-size: 14px;
    } .el-textarea__inner {

        font-size: 14px;
        font-family: SourceHanSansCN, SourceHanSansCN;
      }

    .el-form-item__label {
      font-size: 14px;
      text-align: center;
      border-right: 2px solid white;
    }

    .el-button {
      width: 150px;
      height: 45px;
      line-height: 35px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #2F6EFF;
      padding: 0;

      a {
        display: block;
      }
    }

    .el-button--primary {
      border: 1px solid #2F6EFF;
      background: #2F6EFF;
      color: #fff;
    }
}

}
.phone {
  .topback{
    z-index: 9;
    width: 99vw;
    padding: 1vh  15px;
    //background-color: rgb(52, 48, 48,0.3);;

  }
  ::v-deep .el-upload-list__item:first-child{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
  }
  ::v-deep .el-upload-list__item{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
    border-radius: 0;
  }
  .title1 {
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0px ;
  }

  .title2 {
    color: gray;
    text-align: left;
    width: 100%;
    padding:0px 20px;
    line-height: 25px;
  }
  .submitStyle{
    cursor: pointer;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content:  center;;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #e8edf5
  }
  .submitStyle:hover{
    //font-weight: bold;
    background-color: #efdec3
  }
  .starClas{
    height: 71px;
    display: flex;
    justify-content:  flex-start;;
    align-items: center;
    font-size: 14px;
    border-top: 2px solid white;
    .starClasleft {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      font-size: 14px;
      height: 100%;
      border-right: 2px solid white;
    }
    .starClasright{
      width: 70%;
      height: 100%;
      border-right: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      .evalTitle{
        width: 100%;
        text-align: center;
        padding-top: 20px;
      }
      /* 评价星星 */
      .evalStar{
        width: 100%;
        display: flex;
        text-align: center;
        margin:0 auto;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        //padding-left: calc(50% - 100px);
      }
      .starImg{
        cursor: pointer;
        width: 20px;
        margin:0 5px;
        height: 20px;
      }

    }

  }
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
    border-top:2px solid white;
  }
  ::v-deep .el-form-item__error {
    left: 15px !important;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
  }


  ::v-deep {
    .el-dialog__body {
      padding: 30px 50px 30px 35px;
      //color: #606266;
      font-size: 14px;
      word-break: break-all;
    }

    .el-dialog__header {
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }

    .el-input__inner {
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      border-radius: 5px !important;
      border: none !important;
      background-color: transparent;

    }

    .el-textarea {
      line-height: 35px;
      border-radius: 5px !important;
      border: 1px solid #9b9a9a !important;
      position: relative;
      font-size: 14px;
    }

    .el-textarea__inner {

      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
    }

    .el-form-item__label {
      font-size: 14px;
      text-align: center;
      border-right: 2px solid white;
      white-space: pre-line ;
      /*text-align-last: justify;*/
      /*text-align:justify;*/
      //margin-top:-4px;
      //line-height:25px;
      //text-justify:distribute-all-lines;

    }

    .el-button {
      width: 150px;
      height: 45px;
      line-height: 35px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #2F6EFF;
      padding: 0;

      a {
        display: block;
      }
    }

    .el-button--primary {
      border: 1px solid #2F6EFF;
      background: #2F6EFF;
      color: #fff;
    }
  }}
</style>
