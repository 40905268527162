<template>
  <div>
    <div v-if="isMobile===false" class="PC">
      <el-dialog
          :before-close="handleClose"
          :visible.sync="dialogVisible"
          width="68vw">
        <div>
          <div class="title1" ><span style="color:#be0000">User Feedback for Website</span></div>
          <div class="title2">
            <span style="text-align: left;margin-bottom: 2vh">Dear Users:
</span><br>
            We strive to enhance your online experience and would deeply appreciate your feedback. Each suggestion or comment you provide is vital for our ongoing development. We are committed to continuously refining our websites based on your input, aiming to offer you a more pleasant online experience. Thank you for your time and continued support!
          </div>
          <div style="margin-top:15px;background-color: #d0dced">

            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="40%">
              <el-form-item style="margin-bottom: 50px" label="Type of Issue" prop="type">
               <div style="margin-left: 15px">
                 <el-radio v-model="ruleForm.type" label="1">Program Bug</el-radio>
                 <el-radio v-model="ruleForm.type" label="2">Function suggestions   </el-radio>
                 <el-radio v-model="ruleForm.type" label="3">Content Feedback </el-radio>
                 <el-radio v-model="ruleForm.type" label="4">Others</el-radio>
               </div>
              </el-form-item>

              <el-form-item  label="Your Opinions or Suggestions" prop="suggestion">

                <el-input v-model="ruleForm.suggestion" placeholder="Please describe any problems or suggestions you have. For customer service inquiries, kindly use our Customer service Onilne chat, Thank you!(15-200 words)"></el-input>

              </el-form-item>

              <el-form-item  style="background-color: #e8edf5"  label="Screenshots or Files of the Issue" prop="attachment">
                <el-upload
                    ref="field102"
                    :file-list="fileList"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :before-upload="fileUpload"
                    class="inputStyle"
                    :show-file-list="true"
                    action="/api/file/upload"
                    multiple>
                  <div style="display: flex;margin-left: 15px">
                    <div><i class="el-icon-upload"></i></div>
                    <div class="el-upload__text" style="margin-left: 10px"><em>
                      （Please upload, if applicable)</em></div>
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item label="Your Name" prop="user_name">
                <el-input v-model="ruleForm.user_name" placeholder="please enter your name"></el-input>
              </el-form-item>
              <el-form-item style="background-color: #e8edf5"  label="Contact Details（Phone/Email）" prop="mail">
                <el-input v-model="ruleForm.mail" placeholder="Please be assured that your contact information will be kept confidential and used solely for internal reference"></el-input>
              </el-form-item>

              <el-form-item style="text-align: center">

              </el-form-item>
            </el-form>
          </div>
          <div style="text-align: center" class="submitStyle" @click="submitForm(ruleForm)">Submit
          </div>
        </div>
      </el-dialog>
    </div>
    <div v-if="isMobile===true" class="phone" style="font-size: 14px">
      <div class="topback">
        <headtip @showDialog="showPartner"></headtip>
      </div>
      <div class="title1" ><span style="color:#be0000">User Feedback for Website</span></div>
      <div class="title2">
            <span style="text-align: left;margin-bottom: 2vh">Dear Users:
</span><br>
        We strive to enhance your online experience and would deeply appreciate your feedback. Each suggestion or comment you provide is vital for our ongoing development. We are committed to continuously refining our websites based on your input, aiming to offer you a more pleasant online experience. Thank you for your time and continued support!
      </div>
      <div style="margin-top:15px;background-color: #d0dced">

        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="40%">
          <el-form-item style="margin-bottom: 50px" label="
          Type of
          Issues
" prop="type" >
            <div style="margin-left: 15px">
              <el-radio v-model="ruleForm.type" label="1">Program Bug</el-radio>
              <el-radio v-model="ruleForm.type" label="4">Others</el-radio>
              <el-radio v-model="ruleForm.type" label="2">Function suggestions   </el-radio>
              <el-radio v-model="ruleForm.type" label="3">Content Feedback </el-radio>
              </div>
          </el-form-item>

          <el-form-item  label="Suggestions" prop="suggestion">

            <el-input v-model="ruleForm.suggestion" placeholder="Please describe any problems or suggestions you have. For customer service inquiries, kindly use our Customer service Onilne chat, Thank you!(15-200 words)"></el-input>

          </el-form-item>

          <el-form-item  style="background-color: #e8edf5"  label="Attachments" prop="attachment">
            <el-upload
                ref="field102"
                :file-list="fileList"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="fileUpload"
                class="inputStyle"
                :show-file-list="true"
                action="/api/file/upload"
                multiple>
              <div style="display: flex;margin-left: 15px">
                <div><i class="el-icon-upload"></i></div>
                <div class="el-upload__text" style="margin-left: 10px"><em>
                  （Please upload, if applicable)</em></div>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="Your Name" prop="user_name">
            <el-input v-model="ruleForm.user_name" placeholder="please enter your name"></el-input>
          </el-form-item>
          <el-form-item style="background-color: #e8edf5"  label="Contact Details" prop="mail">
            <el-input v-model="ruleForm.mail" placeholder="Please be assured that your contact information will be kept confidential and used solely for internal reference"></el-input>
          </el-form-item>

          <el-form-item style="text-align: center">

          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center" class="submitStyle" @click="submitForm(ruleForm)">Submit
      </div>
<bottomTip></bottomTip>

    </div>
    <partnerTip
        :dialogVisible="dialog"
        @hideDialog="hideDialog"
    ></partnerTip>
    <successTip
        :dialogVisible="dialogconsult"
        @hideDialog="hide"
    ></successTip>

  </div>

</template>
<script>
import successTip from "@/components/successTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import headtip from "@/views/headTip.vue";
export default {
  components: {headtip, successTip,bottomTip},
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    thisTitle: {
      type: String,
      // required: true
    }
  },
  data() {
    return {

      // localDialogVisible: this.dialogVisible,
      userId:localStorage.getItem("userId"),
      dialogconsult: false,
      dialog:false,
      complete:localStorage.getItem("infoComplete"),
      token: localStorage.getItem("access_token"),
      isMobile: false,
      languageSelect: '',
      title:'',
      grade:0,
      fileList: [],
      ruleForm: {
        type: '',
        suggestion: '',
        attachment:'',
        user_name:'',
        mail:''
      },
      rules: {

        suggestion:[
          {required: true, message: 'please enter your opinions or suggestions', trigger: 'blur'}
        ]

      }
    }
  },
  mounted() {

    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  computed(){

  },
  created() {
    console.log(typeof (this.thisTitle))
    if(this.thisTitle===undefined||this.thisTitle===""){
      this.thisTitle="安东地质工程云平台"
    }
    console.log(this.title)
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }

  },
  methods: {
    showPartner(){
      this.dialog=true;
    },
    changeGradequality_evaluation(num){
      let that = this
      that.$data.ruleForm.quality_evaluation=num
    },
    changeGradeperformance_evaluation(num){
      let that = this
      that.$data.ruleForm.performance_evaluation=num
    },
    changeGradetimeliness_evaluation(num){
      let that = this
      that.$data.ruleForm.timeliness_evaluation=num
    },
    changeGradecost_evaluation(num){
      let that = this
      that.$data.ruleForm.cost_evaluation=num
    },
    changeGradeserve_evaluation(num){
      let that = this
      that.ruleForm.serve_evaluation=num
      that.$set(that.ruleForm.serve_evaluation,num)
      console.log(that.ruleForm.serve_evaluation)
    },
    hideDialogin() {
      // this.dialogVisibleLogin = false;
      // location.reload()
      this.$forceUpdate()
    },
    hide() {
      this.dialogconsult = false;
      // location.reload()
      this.$forceUpdate()
    },
    hideDialog() {
      this.dialog = false;
      // location.reload()
      this.$forceUpdate()
    },
    hideComplete(){
      // location.reload()
      // this.$forceUpdate()
      // this.dialogComplete = false;
      // this.localDialogVisible=false
    },
    handleClose() {
      this.ruleForm.type =""
      this.ruleForm.suggestion =""
      this.ruleForm.attachment=""
      this.ruleForm.user_name=""
      this.ruleForm.mail=""
      this.$emit('hideDialog')
    },
    submitForm(formName) {
      formName.consultUrl = window.location.href
      console.log(formName)
      if (formName.suggestion === "") {
        this.$message('please enter your opinions or suggestions');
      } else {
        this.$axios.post("/api/search/addWebFeedback",formName, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token
          },
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.dialogconsult = true
                this.handleClose()
                this.resetForm(formName)
              } else {
                this.$message('Failed to submit, please try again.');
              }

            }).catch(error => {
          console.log(error)
        })

      }

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert('submit!');
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    // 文件上传
    async fileUpload(file) {
      try {
        // 构建表单数据
        const formData = new FormData();
        formData.append('file', file);

        // 向后端发送文件到 /common/upload 接口
        const response = await this.$axios.post('/api/file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.uploadedFileValue = response.data;
        console.log(response.data); // 打印响应数据到控制台
        this.ruleForm.attachment=response.data.msg
        // 返回 true 允许上传，false 禁止上传
        return true;
      } catch (error) {
        console.error('Failed to upload.:', error);
        return false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("预览")
      console.log(file);
      // this.showModal = true;
      window.open(file.response.url)
      // this.largeSrc=file.response.url
    },

    beforeRemove(file, fileList) {
      console.log(fileList)
      return this.$confirm(`Definitive removal  ${ file.name }？`);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    "$store.state.userId"(newValue) {
      this.userId = newValue;
    },
    // dialogVisible() {
    //   this.localDialogVisible = false;
    //   this.dialogComplete = false;
    //
    //   // if (newVal&&!this.userId) {
    //   //   // 如果 dialogVisible 为 true 且 userId 不存在，设置 localDialogVisible 为 true
    //   //   this.dialogVisibleLogin=true
    //   //   this.localDialogVisible = false;
    //   // }
    //   // else if(newVal&&this.userId){
    //   //   if(this.complete==1){
    //   //     this.localDialogVisible = true;
    //   //   }
    //   //   else{
    //   //     this.dialogComplete = true;
    //   //     this.localDialogVisible = false;
    //   //   }
    //   // }
    //   // else{
    //   //   this.localDialogVisible = false;
    //   //   this.dialogComplete = false;
    //   // }
    // },
  },
};
</script>
<style lang="scss" scoped>
.PC{

  ::v-deep .el-upload-list__item:first-child{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
  }
  ::v-deep .el-upload-list__item{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
    border-radius: 0;
  }
  .title1 {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    margin-top: -35px;
  }

  .title2 {
    color: gray;
    text-align: left;
    font-size: 16px;
    width: 100%;
    margin-top: 15px;
    line-height: 25px;
  }
  .submitStyle{
    cursor: pointer;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content:  center;;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #cdd9ea
  }
  .submitStyle:hover{
    //font-weight: bold;
    background-color: #efdec3
  }
  .starClas{
    height: 35px;
    display: flex;
    justify-content:  flex-start;;
    align-items: center;
    font-size: 14px;
    border-top: 2px solid white;
    .starClasleft {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      font-size: 14px;
      height: 100%;
      border-right: 2px solid white;
    }
    .starClasright{
      width: 70%;
      height: 100%;
      border-right: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      .evalTitle{
        width: 100%;
        text-align: center;
        padding-top: 20px;
      }
      /* 评价星星 */
      .evalStar{
        width: 100%;
        display: flex;
        text-align: center;
        margin:0 auto;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        //padding-left: calc(50% - 100px);
      }
      .starImg{
        cursor: pointer;
        width: 20px;
        margin:0 5px;
        height: 20px;
      }

    }

  }
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
    border-top:2px solid white;
  }
  ::v-deep .el-form-item__error {
    left: 15px !important;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
  ::v-deep {
    .el-dialog__body {
      padding: 30px 50px 30px 35px;
      //color: #606266;
      font-size: 14px;
      word-break: break-all;
    }

    .el-dialog__header {
      padding: 10px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }

    .el-input__inner {
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      border-radius: 5px !important;
      border: none !important;
      height: 30px;
      background-color: transparent;

    }

    .el-textarea {
      line-height: 35px;
      border-radius: 5px !important;
      border: 1px solid #9b9a9a !important;
      position: relative;
      font-size: 14px;
    } .el-textarea__inner {

        font-size: 14px;
        font-family: SourceHanSansCN, SourceHanSansCN;
      }

    .el-form-item__label {
      font-size: 14px;
      text-align: center;
      border-right: 2px solid white;
    }

    .el-button {
      width: 150px;
      height: 45px;
      line-height: 35px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #2F6EFF;
      padding: 0;

      a {
        display: block;
      }
    }

    .el-button--primary {
      border: 1px solid #2F6EFF;
      background: #2F6EFF;
      color: #fff;
    }

  }
}
.phone {
  .topback{
    z-index: 9;
    width: 99vw;
    padding: 1vh  15px;
    //background-color: rgb(52, 48, 48,0.3);;

  }
  ::v-deep .el-upload-list__item:first-child{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
  }
  ::v-deep .el-upload-list__item{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
    border-radius: 0;
  }
  .title1 {
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0px ;
  }

  .title2 {
    color: gray;
    text-align: left;
    width: 100%;
    padding:0px 20px;
    line-height: 25px;
  }
  .submitStyle{
    cursor: pointer;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content:  center;;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #e8edf5
  }
  .submitStyle:hover{
    //font-weight: bold;
    background-color: #efdec3
  }
  .starClas{
    height: 46px;
    display: flex;
    justify-content:  flex-start;;
    align-items: center;
    font-size: 14px;
    border-top: 2px solid white;
    .starClasleft {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      font-size: 14px;
      height: 100%;
      border-right: 2px solid white;
    }
    .starClasright{
      width: 70%;
      height: 100%;
      border-right: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center ;
      .evalTitle{
        width: 100%;
        text-align: center;
        padding-top: 20px;
      }
      /* 评价星星 */
      .evalStar{
        width: 100%;
        display: flex;
        text-align: center;
        margin:0 auto;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        //padding-left: calc(50% - 100px);
      }
      .starImg{
        cursor: pointer;
        width: 20px;
        margin:0 5px;
        height: 20px;
      }

    }

  }
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
    border-top:2px solid white;
  }
  ::v-deep .el-form-item__error {
    left: 15px !important;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
  }


  ::v-deep {
    .el-dialog__body {
      padding: 30px 50px 30px 35px;
      //color: #606266;
      font-size: 14px;
      word-break: break-all;
    }

    .el-dialog__header {
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }

    .el-input__inner {
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      border-radius: 5px !important;
      border: none !important;
      background-color: transparent;

    }

    .el-textarea {
      line-height: 35px;
      border-radius: 5px !important;
      border: 1px solid #9b9a9a !important;
      position: relative;
      font-size: 14px;
    }

    .el-textarea__inner {

      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
    }

    .el-form-item__label {
      font-size: 14px;
      text-align: center;
      border-right: 2px solid white;
      white-space: pre-line ;
      /*text-align-last: justify;*/
      /*text-align:justify;*/
      //margin-top:-4px;
      //line-height:25px;
      //text-justify:distribute-all-lines;

    }

    .el-button {
      width: 150px;
      height: 45px;
      line-height: 35px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #2F6EFF;
      padding: 0;

      a {
        display: block;
      }
    }

    .el-button--primary {
      border: 1px solid #2F6EFF;
      background: #2F6EFF;
      color: #fff;
    }
  }}
</style>
